<template>
    <div
        v-if="allowedOauthService.length"
        class="form-block__social"
    >
        <div class="social-login__description-wrapper">
            <span>
                <p class="social-login__text">
                    {{ mode === `login` ? 'or login with' : 'or sign up with' }}
                </p>
            </span>
            <div class="social-login__line" />
        </div>
        <div
            class="social-login"
            :class="{'social-login-register': mode === `register`}"
        >
            <button
                v-for="service in allowedOauthService"
                :key="service"
                class="social-login-btn"
                :class="{
                    'social-login-btn_facebook': service === 'facebook',
                    'social-login-btn_google': service === 'google',
                    'social-login-btn_apple': service === 'apple'
                }"
                @click.prevent="submit(service)"
            >
                <div
                    v-if="loading"
                    class="loading-center"
                >
                    <button-loader />
                </div>
                <template v-else>
                    <component
                        :is="`${service}-icon`"
                        width="24px"
                    />
                    <span
                        class="social-login-btn__desc"
                    >{{ $t(`components.social.${service}`) }}</span>
                </template>
            </button>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_CLIENT_SOCIAL_LOGIN_GET_URL
} from '@/store/modules/client/action-types';
import {
    OPEN_SOCIAL_MODAL
} from '@/store/modules/client/mutation-types';
import FacebookIcon from '@/components/common/icons/Facebook'
import GoogleIcon from '@/components/common/icons/Google'
import AppleIcon from '@/components/common/icons/Apple'
import ButtonLoader from '@/components/common/ButtonLoader.vue';
import { getOauthServices } from '@/services/settings/';

const {
    mapActions: mapClientActions,
    mapMutations: mapClientMutations
} = createNamespacedHelpers('client');

export default {
    components: {
        FacebookIcon,
        GoogleIcon,
        AppleIcon,
        ButtonLoader
    },
    props: {
        mode: {
            type: String
        }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        allowedOauthService() {
            return getOauthServices()
        }
    },
    methods: {
        ...mapClientActions({
            ACTION_CLIENT_SOCIAL_LOGIN_GET_URL
        }),
        ...mapClientMutations({
            OPEN_SOCIAL_MODAL
        }),
        async submit(service) {
            // get the url from the service
            this.loading = true
            const { url } = await this[ACTION_CLIENT_SOCIAL_LOGIN_GET_URL](service);
            // send user to Oauth service for authentication
            window.location.href = url;
            this.loading = false

            // const width = window.innerWidth > 768 ? window.innerWidth / 2 : window.innerWidth;
            // const heigth = window.innerHeight / 2 < 400 ? window.innerHeight : window.innerHeight / 2;
            // const left = (window.innerWidth - width) / 2
            // const top = (window.innerHeight - heigth) / 2
            // const windowFeatures = `left=${left},top=${top},width=${width},height=${heigth}`;
            // this[OPEN_SOCIAL_MODAL]({ url, windowFeatures })
        }
    }
};
</script>

<style lang="scss">
.social-login {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px 20px;
    &-register {
        flex-flow: row wrap;
    }
    &__description-wrapper{
        position: relative;
        display: flex;
        justify-content: center;
    }
    &__text{
        text-align: center;
        z-index: 2;
        position: relative;
        background-color: #fff;
        padding: 0 10px;
        font-weight: 400;
        font-size: 14px;
        color: #61646A;
    }
    &__line{
        position: absolute;
        display: flex;
        top: 8px;
        z-index: 1;
        border-bottom: 1px solid #e7ecf4;
        width: 100%;
    }
}
.social-login-btn {
    flex-grow: 1;
    gap: 5px;
    padding: 10px 16px;
    height: 50px;
    border-radius: $btn-base__border-radius;
    border: 1px solid #bebebe;
    background: white;
    transition: 0.3s;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    &__active {
        background: #3988f2 ;
        border: 1px solid #3988f2;
        justify-content: space-between;
        color: #fff;
        svg {
            fill: #fff;
            path {
                fill: #fff;
            }
        }
    }
    &_facebook {
        background: #1877F2 ;
        border: 1px solid #1877F2;
        color: #fff;
    }
    &__desc {
        font-size: 16px;
        font-weight: 400;
        text-transform: none;
        flex-grow: 2;
        text-align: center;
    }
    &_facebook,
    &_google,
    &_apple {
        &:hover {
            opacity: 0.8;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
        }
    }

    &_apple {
        svg {
            width: 20px;
            height: 20px;
        }
    }
}
.loading-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
