<template>
    <entering-form-layout>
        <div class="authentication__form-wrapper">
            <div class="authentication__form">
                <!-- signing login section -->
                <!-- signing login section -->
                <form
                    class="signin-section form-block"
                    @submit.prevent="validate"
                >
                    <div class="form-block__body">
                        <div class="form-block__header">
                            <h2 class="form-block__title">
                                {{ $t('pages.login.title') }}
                            </h2>
                            <p class="form-block__description">
                                Not a member yet,
                                <router-link
                                    class="colored-link"
                                    :to="{ name: 'register', params: {} }"
                                >
                                    register in seconds
                                </router-link>
                            </p>
                        </div>
                        <!-- email -->
                        <form-input
                            v-model.trim="emailTrimed"
                            :field.sync="emailTrimed"
                            :legend="$t('pages.login.email')"
                            prepend-icon="EmailOutlineIcon"
                            type="text"
                            :errors="validationMessage($v.email)"
                            :is-valid="$v.email.$dirty && !$v.email.$anyError"
                            @input.native="$v.email.$touch()"
                            @blur.native="$v.email.$touch()"
                        />
                        <!-- email -->
                        <!-- password -->
                        <form-input
                            v-model="$v.password.$model"
                            :field.sync="password"
                            name="pass-word"
                            :legend="$t('pages.login.password')"
                            prepend-icon="LockIcon"
                            autocomplete="new-password"
                            :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                            :type="showPassword ? 'text' : 'password'"
                            :errors="validationMessage($v.password)"
                            :is-valid="$v.password.$dirty && !$v.password.$anyError"
                            @clicked="showPassword = !showPassword"
                            @input.native="$v.password.$touch()"
                            @blur.native="$v.password.$touch()"
                        />
                        <!-- password -->
                        <!-- OTP -->
                        <form-input
                            v-if="showOtp"
                            v-model="$v.code.$model"
                            outlined
                            :field.sync="code"
                            legend="Code"
                            placeholder="123 456"
                            :errors="validationMessage($v.code)"
                            :is-valid="$v.code.$dirty && !$v.code.$anyError"
                            @input.native="$v.code.$touch()"
                            @blur.native="$v.code.$touch()"
                        />
                        <!-- OTP -->
                        <!-- submit -->
                        <custom-button
                            :loading="loading"
                            class="btn-base btn-main button-width-100"
                        >
                            {{ $t('pages.login.submit') }}
                        </custom-button>
                        <!-- submit -->
                        <!-- remember me -->
                        <div class="form-block__checkbox">
                            <div class="form-block__checkbox--container">
                                <form-checkbox
                                    v-model="chk_remember"
                                    check="login"
                                />
                                <span class="">{{ $t('pages.login.remember') }}</span>
                                <span
                                    class="custom-tooltip"
                                >
                                    <p>?</p>
                                    <span>This saves your session for a week. Use it, if you are on your private computer.</span>
                                </span>
                            </div>
                            <!-- forgot password link -->
                            <router-link
                                class="colored-link"
                                :to="{ name: 'forgot-password', params: {} }"
                            >
                                {{ $t('pages.login.forgot') }}
                            </router-link>
                            <!-- forgot password link -->
                        </div>
                        <!-- remember me -->
                        <!-- social login -->
                        <social-login mode="login" />
                        <!-- social login -->
                    </div>
                    <div class="form-block__footer" />
                </form>
                <!-- signing login section -->
            </div>
            <!-- signing login section -->
        </div>
    </entering-form-layout>
</template>

<script>
// import { eventBus } from '@/helpers/event-bus/'

// validation
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages';
import { createNamespacedHelpers } from 'vuex';
import formValidationMixin from '@/mixins/formValidationMixin';
import { validationRules } from '@/validation/login/Rules';
import { formMessages } from '@/validation/login/Messages';
import { eventBus } from '@/helpers/event-bus/'

import EnteringFormLayout from '@/layouts/EnteringFormLayout';
import SocialLogin from '@/components/common/SocialLogin.vue';

import {
    ACTION_LOGIN_CLIENT
} from '@/store/modules/client/action-types';

const { mapActions: mapClientActions } = createNamespacedHelpers('client');

export default {
    name: 'LoginForm',
    components: {
        EnteringFormLayout,
        SocialLogin
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data: () => ({
        email: '',
        emailTrimed: '',
        password: '',
        showPassword: false,
        loading: false,
        chk_remember: false,
        // otp
        showOtp: false,
        correctOTP: true,
        code: null
    }),
    watch: {
        emailTrimed(value) {
            this.email = value.trim()
        },
        code() {
            if (!this.correctOTP) {
                this.correctOTP = true
                this.$nextTick(() => {
                    this.$v.code.$reset()
                })
            }
        }
    },
    methods: {
        ...mapClientActions([
            ACTION_LOGIN_CLIENT
        ]),
        validationMessage: validationMessage(formMessages),
        async submit() {
            try {
                this.loading = true
                await this[ACTION_LOGIN_CLIENT]({
                    email: this.email.trim(),
                    password: this.password,
                    remember_me: this.chk_remember,
                    one_time_password: this.code
                })
                this.$router.push({ name: 'auth-intermediate' })
            } catch (err) {
                if (err === 'OTP is required' && !this.showOtp) {
                    this.showOtp = true
                    this.loading = false
                    // this.$emit('twoFa')
                    return
                }
                if (err === 'Wrong OTP' || (err === 'OTP is required' && this.showOtp)) {
                    this.correctOTP = false
                    this.loading = false
                    return
                }
                eventBus.$emit('showSnackBar', err, 'error')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">

.form-block__body {
    .form-input__fieldset input {
        font-size: 17px !important;
    }
}

</style>
